import Vue from "vue";
import VueCookies from 'vue-cookies';
import Element from 'element-ui';
//import 'element-ui/lib/theme-chalk/index.scss';
import './element-variables.scss'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from 'vue-i18n';
import zhlocale from "element-ui/lib/locale/lang/zh-CN";
import jalocale from "element-ui/lib/locale/lang/ja";
import enlocale from "element-ui/lib/locale/lang/en";
import "normalize.css";
import fontawesome from "@fortawesome/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import solid from "@fortawesome/fontawesome-free-solid";
import regular from "@fortawesome/fontawesome-free-regular";
import brands from "@fortawesome/fontawesome-free-brands";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { mutationsType } from "@/store/mutations-type.js";
//import {  mapMutations } from "vuex";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
import { ROUTER_PATH } from "@/constants/common.js";
import echarts from 'echarts';

Vue.use(VueI18n);
Vue.use(VueCookies);

const messages = {
  ja: Object.assign(require("./assets/languages/jp"),jalocale),
  zh: Object.assign(require("./assets/languages/zh"),zhlocale),
  en: Object.assign(require("./assets/languages/en"),enlocale)
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "en", // set locale
  messages// set locale messages
});

Vue.use(Element,{
  i18n: (key, value) => i18n.t(key, value)
});

//const echarts =require("echarts");
Vue.use(echarts);
Vue.prototype.$echarts = echarts;

fontawesome.library.add(solid);
fontawesome.library.add(regular);
fontawesome.library.add(brands);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (!to.meta.needLogin) {
    console.log("next:"+to.path);
    next();
  } else {
    if(to.path===ROUTER_PATH.LOGIN_OUT){
      //console.log("logout");
      localStorage.setItem(LOCAL_STORAGE_KEY.RPA_TOKEN, "");
      localStorage.setItem(LOCAL_STORAGE_KEY.RPA_USER_ID, "");
      store.commit(mutationsType.INIT_ACCOUNT_INFO, {});
      store.commit(mutationsType.SET_LOGIN_PRE_PATH, from.path);
      next(ROUTER_PATH.LOGIN);
    }
    else if (
      isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
      ) ||
      isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      )
    ) {
      store.commit(mutationsType.SET_LOGIN_PRE_PATH, to.path);
      next(ROUTER_PATH.LOGIN);
    } else {
      next();
    }
  }
});
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.filter(
  'cutString',function(val,s){
    let newstr = val;
    if (val && val.length > s) {
      newstr = val.substring(0,s) + "..."
    }

    return newstr;

  }
);
