import Vue from "vue";
import VueRouter from "vue-router";
import { ROUTER_PATH } from "@/constants/common.js";

Vue.use(VueRouter);

const routes = [
  {
    path: ROUTER_PATH.LOGIN,
    name: "login",
    component: () => import("../views/account/Login.vue"),
    meta: { title: "ログイン", needLogin: false }
  },
  {
    path: ROUTER_PATH.ACCOUNT_FORGET_PASSWORD,
    name: "forgetPassword",
    component: () => import("../views/account/ForgetPassword.vue"),
    meta: { title: "パスワードリセット", needLogin: false }
  },
  {
    path: "/",
    name: "layout",
    component: () => import("../views/layout"),
    redirect:'/kintone/DocList',
    children: [
      {
        path: "/kintone/Top",
        name: "Top",
        component: () => import("../views/kintone/Top.vue"),
        meta: { title: "トップ", needLogin: true }
      },
      {
        path: "/kintone/Maintenance",
        name: "Maintenance",
        component: () => import("../views/kintone/Maintenance.vue"),
        meta: { title: "準備中", needLogin: true }
      },
      {
        path: "/kintone/DocList",
        name: "DocList",
        component: () => import("../views/kintone/DocList.vue"),
        meta: { title: "伝票管理", needLogin: true }
      },
      {
        path: "/kintone/DocConfig",
        name: "DocConfig",
        component: () => import("../views/kintone/DocConfig.vue"),
        meta: { title: "伝票ロボット設定", needLogin: true }
      },
      {
        path: "/kintone/TaskList",
        name: "TaskList",
        component: () => import("../views/kintone/TaskList.vue"),
        meta: { title: "伝票ロボット実行履歴", needLogin: true }
      },
      {
        path: "/kintone/PDFList",
        name: "PDFList",
        component: () => import("../views/kintone/PDFList.vue"),
        meta: { title: "帳票出力管理", needLogin: true }
      },
      // {
      //   path: "/kintone/PDFConfig",
      //   name: "PDFConfig",
      //   component: () => import("../views/kintone/PDFConfig.vue"),
      //   meta: { title: "帳票出力設定", needLogin: true }
      // },
      {
        path: "/kintone/Account",
        name: "Account",
        component: () => import("../views/kintone/Account.vue"),
        meta: { title: "アカウント設定", needLogin: true }
      },
      
      {
        path: ROUTER_PATH.ACCOUNT_INFO,
        name: "accountInfo",
        component: () => import("../views/account/AccountInfo.vue"),
        meta: { title: "账户信息", needLogin: true }
      },
      {
        path: ROUTER_PATH.ACCOUNT_RESET_PASSWORD,
        name: "accountResetPassword",
        component: () => import("../views/account/ResetPassword.vue"),
        meta: { title: "重置密码", needLogin: true }
      },
      {
        path: ROUTER_PATH.LOGIN_OUT,
        name: "accountLoginOut",
        component: () => import("../views/account/LoginOut.vue"),
        meta: { title: "登出", needLogin: false }
      },
      {
        path: ROUTER_PATH.ACCOUNT_FORGET_PASSWORD,
        name: "accountForgetPassword",
        component: () => import("../views/account/ForgetPassword.vue"),
        meta: { title: "忘记密码", needLogin: false }
      },
      
    ]
  },
  {
    path: "/error",
    name: "errorPage",
    component: () => import("../views/layout"),
    children: [
      {
        path: "/404",
        name: "Page404",
        component: () => import("../views/errorPage/404.vue"),
        meta: { title: "Page 404" }
      },
      {
        path: "/401",
        name: "Page401",
        component: () => import("../views/errorPage/401.vue"),
        meta: { title: "Page 401" }
      }
    ]
  },
  {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  routes
});

export default router;
