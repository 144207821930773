module.exports = {
    "common":{
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "login":{
        "title":"会員ログイン",
        "username":"ユーザー名",
        "password":"パスワード",
        "login":"ログイン",
        "forgetPwd":"パスワード忘れた",
        "keep":"自動ログイン",
        "regist":"試してみる",
        "err1":"ユーザー名を入力してください。",
        "err2":"パスワードを入力してください。"
    },
    "account":{
        "account":"アカウント",
        "info":"アカウント情報",
        "contactor":"名前",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "update":"更新",
        "cancel":"取消",
        "err1":"名前を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールアドレスを入力してください。",
        "err6":"正しいメールアドレスを入力してください。"
    },
    "forgetPWD":{
        "mail":"メール",
        "reset":"パスワードリセット",
        "back":"戻る",
        "err1":"メールアドレスを入力してください。",
        "suc1":"パスワードリセットは成功しました。結果についてメールで確認してください。"
    },
    "resetPWD":{
        "account":"アカウント",
        "udpatePWD":"パスワード更新",
        "oldPassword":"パスワード",
        "newPassword":"新パスワード",
        "newPassword2":"確認パスワード",
        "update":"更新",
        "err1":"パスワードが一致していません。",
        "err2":"パスワードを入力してください。",
        "err3":"8桁から20桁の内容を入力してください。",
        "err4":"新パスワードを入力してください。",
        "err5":"確認パスワードを入力してください。"
    },
    "addGroup":{
        "admin":"管理",
        "addRole":"グループ新規",
        "role":"グループ情報",
        "name":"グループ名",
        "process":"プロセス",
        "add":"新規",
        "back":"戻る"
    },
    "addUser":{
        "admin":"管理",
        "addUser":"ユーザー新規",
        "account":"ユーザー情報",
        "contactor":"ユーザー名",
        "password":"パスワード",
        "A":"管理者",
        "B":"業務員",
        "C":"財務",
        "T":"技術",
        "role":"ロール",
        "group":"グループ",
        "mobile":"携帯",
        "mail1":"メール",
        "systemmail":"システム通知",
        "billmail":"請求書",
        "notice":"通知",
        "notnotice":"通知しない",
        "create":"新規",
        "back":"戻る",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "listGroup":{
        "group":"件",
        "add":"新規",
        "name":"グループ",
        "bindprocess":"プロセス",
        "process":"処理",
        "remove":"削除",
        "confirm":"該当ロールを削除してよろしいでしょうか。",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "listUser":{
        "user":"件",
        "add":"新規",
        "name":"名前",
        "role":"ロール",
        "group":"グループ",
        "process":"処理",
        "edit":"修正",
        "remove":"削除",
        "confirm":"該当ユーザーを削除してよろしいでしょうか。?",
        "popup":"メッセージ",
        "ok":"OK",
        "cancel":"取消"
    },
    "updateUser":{
        "manage":"管理",
        "updateUser":"ユーザー情報更新",
        "update":"更新",
        "err1":"ユーザー名を入力してください。",
        "err2":"1桁から20桁の内容を入力してください。",
        "err3":"携帯番号を入力してください。",
        "err4":"11桁の携帯番号を入力してください。",
        "err5":"メールを入力してください。",
        "err6":"正しいメールアドレスを入力してください。",
        "err7":"パスワードを入力してください。",
        "err8":"正しいパスワードを入力してください。"
    },
    "payReport":{
        "totalCount":"件",
        "month":"月",
        "functionName":"機能",
        "currentPlan":"セット",
        "extraFee":"セット外費用",
        "totalFee":"アカウント変更",
        "balance":"アカウント残額",
        "date":"処理時間"
    },
    "processReview":{
        "auto":"自動",
        "manual":"手動",
        "command":"実行",
        "resource":"リソース",
        "back":"返回"
    },
    "process":{
        "my":"マイプロセス",
        "start":"起動...",
        "stop":"終了...",
        "confirm1a":"プロセス",
        "confirm1b":"をオンラインにしてよろしいでしょうか。",
        "confirm2a":"プロセス",
        "confirm2b":"をオフラインにしてよろしいでしょうか。"
    },
    "monitor":{
        "input":"ファイル",
        "robotName":"ロボット",
        "functionName":"機能",
        "date":"日付",
        "start":"開始",
        "end":"終了",
        "result":"結果",
        "process":"処理",
        "redo":"再起動",
        "note":"備考",
        "unit":"件"
    },
    "myrobot":{
        "confirm1a":"ロボット",
        "confirm1b":"を起動してよろしいでしょうか",
        "confirm2a":"ロボット",
        "confirm2b":"を停止してよろしいでしょうか",
        "confirm3a":"ロボット",
        "confirm3b":"を更新してよろしいでしょうか",
        "start":"起動...",
        "stop":"終了...",
    },
    "runningReport":{
        "graph":"グラフ",
        "calling":"実行回数",
        "durationAvg":"平均実行時間（秒）",
        "persent":"成功率",
        "newMonth":"期間",
        "month":"対象月",
        "date":"日付",
        "robotName":"ロボット",
        "functionName":"機能",
        "callSum":"実行回数",
        "callSuccess":"成功回数",
        "callFail":"失敗回数",
        "duration":"実行時間（秒）",
        "list":"一覧",
        "unit":"件"
    },
    "planList":{
        "set":"セット情報",
        "function":"機能",
        "plan":"名前",
        "start":"有効期間開始",
        "end":"有効期間終了",
        "amount":"残回数",
        "balance":"残額"
    },
    "menu":{
        "process":"プロセス",
        "robot":"ロボット",
        "cloud":"クラウド",
        "report":"レポート",
        "bill":"費用",
        "admin":"管理",
        "user":"ユーザー",
        "group":"グループ",
        "account":"アカウント",
        "password":"パスワード修正",
        "logout":"ログアウト"
    },
    "processItem":{
        "online":"オンライン",
        "offline":"オフライン",
        "workday":"稼働日",
        "day":"毎日",
        "detail":"詳細",
        "ver":"版"
    },
    "robotItem":{
        "online":"起動",
        "offline":"終了",
        "config":"設定",
        "update":"更新",
        "ver":"版"
    },
    "robotConfig":{
        "config":"-設定",
        "exe":"実行方法：",
        "by":"頻度",
        "bytime":"定時",
        "time":"間隔時間：",
        "unit":"単位",
        "min":"分",
        "hour":"時間",
        "day":"毎日：",
        "selecttime":"対象時間",
        "weekend":"週末込み",
        "hint":"再起動してください。"
    },
    "report":{
        "function":"機能",
        "byfunction":"機能実行回数集計",
        "byrobot":"ロボット実行回数集計",
        "bymonth":"当月実行回数集計",
    },
    "search":{
        "search":"検索",
        "reset":"レセット",
        "selMonth":"月",
        "selDuration":"期間",
        "selFunction":"機能",
        "today":"当日",
        "week":"当週",
        "month":"当月",
        "selStatus":"状態",
        "success":"成功",
        "fail":"失敗",
        "running":"実行中",
        "selRobot":"ロボット",
        "file":"ファイル"
    }
};