
export default {
  accountInfo: {
    userID: '',
    userName: "",
    companyID: "",
    companyName: "",    
    role: {}
  }
};
