export const LOCAL_STORAGE_KEY = {
  RPA_USER_ID: "RPA_USER_ID",
  RPA_TOKEN: "RPA_TOKEN",
  RPA_TOKEN_EXPIRE: "RPA_TOKEN_EXPIRE"
};

export const ROUTER_PATH={
    HOME:'/customer/myRobot',
    LOGIN:'/account/login',
    LOGIN_OUT:"/account/loginOut",
    ACCOUNT_INFO:'/account/accountInfo',
    ACCOUNT_RESET_PASSWORD:"/account/resetPassword",    
    ACCOUNT_FORGET_PASSWORD:"/account/forgetPassword",
    PROCESS_LIST:"/process/process",
    ROBOT_RUNNING_REPORT:"/robot/runningReport",
    ROBOT_MY_ROBOT:"/robot/myRobot",
    ROBOT_MONITOR:"/robot/monitor",
    PAY_REPORT:"/pay/payReport",
    ADMIN_USER:"/admin/listUser",
    ADD_USER:"/admin/addUser",
    UPDATE_USER:"/admin/updateUser",
    ADMIN_ROLE:"/admin/listRole",
    ADD_ROLE:"/admin/addRole",
    KANBAN:'/kanban/kanban',
    CHATBOT:'/robot/chatBot',
    RPA_INTRO:'/robot/rpaIntro',
    RPA_COMPARE:'/robot/compare',
    RPA_INPUT:'/robot/input',
    RPA_HAND:'/robot/hand',
    CTRM_CUSTOMER: '/ctrm/customer',
    CTRM_CARD: '/ctrm/card',
    CTRM_TAGS: '/ctrm/tags',
    FUTURE_PRICE: '/ctrm/futurePrice',
    CTRM_RATE: '/ctrm/rate',
    CTRM_RP_CUSTOMERALL: '/ctrm/rpcustomerall',
    CTRM_INVOICE: '/ctrm/invoice',
    CTRM_CONTRACT: '/ctrm/contract',
    CTRM_PRODUCT: '/ctrm/product',
    CTRM_CREDIT_SET: '/ctrm/crtset',
    CTRM_CREDIT_LIST: '/ctrm/crtlist',
    CTRM_CREDIT_ADD: '/ctrm/crtadd',
    CTRM_CHATBOT: '/ctrm/chatbot',
    CTRM_LEADS: '/ctrm/leads',
    CTRM_EDITOR: '/ctrm/editor',
    CTRM_TASK: '/ctrm/task',
    CTRM_DEAL: '/ctrm/deal',
    CTRM_FILE: '/ctrm/file',
    CTRM_MEDIA: '/ctrm/media',
    CTRM_OFFICE: '/ctrm/office',
    CTRM_SHIP: '/ctrm/ship',
    CTRM_TOP: '/ctrm/top',
    CTRM_CHATSENDER: '/ctrm/chatsender',
    CTRM_DOCEDIT: '/ctrm/docedit',
    CTRM_SUMMARY: '/ctrm/summary'
}